<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
     
      <el-form-item label="消息类型" prop="msgType">
      <!--   <el-input v-model="dataForm.msgType" placeholder="消息类型"></el-input> -->
        <el-cascader
              clearable
              v-model="dataForm.msgTypes"
              :props="{ checkStrictly: true , value: 'code' , label: 'name'}"
              style="width: 100%;"
              :options="typeList" > 
        </el-cascader>
        <!--  <sysDictSelect :filterable="true" v-model="dataForm.msgType" dictType="msg_type" placeholder="消息类型"/> -->
      </el-form-item>
      <el-form-item label="消息标题" prop="msgTitle">
        <el-input v-model="dataForm.msgTitle" placeholder="消息标题"></el-input>
      </el-form-item>
      <el-form-item label="消息模板" prop="msgTemplate">
        <el-input type="textarea" v-model="dataForm.msgTemplate" placeholder="消息模板"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="reamrk">
        <el-input type="textarea" v-model="dataForm.reamrk" placeholder="备注"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { getById , saveOrUpdate } from '@/api/msg/template.js'
  import { treeDataTranslate } from "@/utils";
  import { getList as getTypeList } from '@/api/msg/type.js'
  export default {
    data () {
      return {
        visible: false,
        isOnSubmit: false,
        typeList: [] ,// 类型
        dataForm: {
          id: null,
          msgType: '',    
          msgTypes: [] ,      
          msgTitle: '',          
          msgTemplate: '',
          remark: ''       
        },
        dataRule: {
          msgType: [
            { required: true, message: '消息类型不能为空', trigger: 'blur' }
          ],          
          msgTitle: [
            { required: true, message: '消息标题不能为空', trigger: 'blur' }
          ],          
          msgTemplate: [
            { required: true, message: '消息模板不能为空', trigger: 'blur' }
          ]          
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id
        this.visible = true
        this.isOnSubmit = false
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            getById(id).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.msgTypeId = data.data.msgTypeId
                this.dataForm.msgTitle = data.data.msgTitle
                this.dataForm.msgTemplate = data.data.msgTemplate
                this.dataForm.remark = data.data.remark
              }
            }).catch((err) => {
              this.$message.error(err)
            })
          }
          this.getTypes()
        })
      },
      getTypes() {
        getTypeList().then(({data})=> {
          const list = data.data; 
          this.typeList = treeDataTranslate(list, "id");
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.isOnSubmit = true
            const data_ = {
              'id': this.dataForm.id || undefined,
              'msgType': this.dataForm.msgType,
              'msgTitle': this.dataForm.msgTitle,
              'msgTemplate': this.dataForm.msgTemplate,
              'remark': this.dataForm.remark
            }
            saveOrUpdate(data_).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 500
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            }).catch((err) => {
              this.$message.error(err)
            }).finally(()=>{
              this.isOnSubmit = false
            })
          }
        })
      }
    }
  }
</script>
