import http from '@/utils/httpRequest'

// 下拉选项获取
export function getMessagePointOptions(params) {
  return http({
          url: http.adornUrl('/msg/type/messagePointOptions'),
          method: 'get',
          params: params
        })
}

// 待办消息下拉选项获取
export function getTodoMessageOptions(params) {
  return http({
          url: http.adornUrl('/msg/type/getTodoMessageTypeOptions'),
          method: 'get',
          params: params
        })
}

// 列表获取
export function getList(params) {
  return http({
          url: http.adornUrl('/msg/type/list'),
          method: 'get',
          params: params
        })
}
//待办列表获取
export function getTodoList(params) {
    return http({
        url: http.adornUrl('/msg/type/todoList'),
        method: 'get',
        params: params
    })
}

// 删除数据
export function deleteData(ids = []) {
	return http({
            url: http.adornUrl("/msg/type/delete"),
            method: "post",
            data: http.adornData(ids, false)
          })
}

// 根据ID 获取
export function getById(id) {
	return http({
        url: http.adornUrl(`/msg/type/info/${id}`),
        method: "get"
        })
}
 
// 更新或新增
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/msg/type/${!data.id ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}