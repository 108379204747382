import http from '@/utils/httpRequest'

// 列表获取
export function getList(params) {
  return http({
    url: http.adornUrl('/msg/template/list'),
    method: 'get',
    params: params
  })
}

// 删除数据
export function deleteData(ids = []) {
  return http({
    url: http.adornUrl("/msg/template/delete"),
    method: "post",
    data: http.adornData(ids, false)
  })
}

// 根据ID 获取
export function getById(id) {
  return http({
    url: http.adornUrl(`/msg/template/info/${id}`),
    method: "get"
  })
}

// 更新或新增
export function saveOrUpdate(data) {
  return http({
    url: http.adornUrl(
      `/msg/template/${!data.id ? "save" : "update"}`
    ),
    method: "post",
    data: data
  })
}
